import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetBonusFilters } from '../graphql/queries'
import SearchIcon from './icons/search'
import Input from './Input'
import SelectInput from './SelectInput'

const initialState = {
    query: null,
    year: null,
}

const initialOptions = {
    years: [],
}

const BonusFilters = forwardRef(({
    onFilterChange,
    allowed,
}, ref) => {

    const { t } = useTranslation()
    const [filterOptions, setFilterOptions] = useState(initialOptions)
    const [filters, setFilters] = useState(initialState)

    const { refetch } = useQuery(GetBonusFilters, {
        onCompleted: (data) => {
            if (data?.getBonusFilters?.years) {
                const yearList = data.getBonusFilters.years.map(x => ({
                    value: x,
                    label: x,
                }))

                if (!yearList?.length) return

                setFilterOptions({
                    ...filterOptions,
                    years: yearList,
                })

                const newFilters = {
                    ...filters,
                    year: yearList[yearList.length - 1].value,
                }

                setFilters(newFilters)

                if (onFilterChange) onFilterChange(newFilters)
            }
        },
    })

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useImperativeHandle(ref, () => ({
        refresh () {
            refetch()
        },
    }))

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters filters-bonus'>
            {
                allowed?.search ?
                <div className='filters-search'>
                    <Input
                        placeholder={ t('bonuses.search') }
                        onChange={(e) => setField('query', e.target.value)}
                        value={filters.query}
                        icon={<SearchIcon />}
                    />
                </div>
                :
                <></>
            }
            {
                allowed?.year ?
                <SelectInput
                    options={filterOptions.years}
                    onChange={(option) => setField('year', option ? parseInt(option.value) : null)}
                    value={filterOptions.years.find(x => parseInt(x.value) === filters.year)}
                    placeholder={t('filters.year')}
                    isClearable={true}
                />
                :
                <></>
            }
        </div>
    )
})

export default BonusFilters